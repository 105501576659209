import React, { Fragment, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Pagina from '../components/Pagina'
import './quem-somos.css'

const QuemSomos = () => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/quem-somos/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
    }
  `
  )
  const [buttonValores, setButtonValores] = useState(false)
  const [buttonVisao, setButtonVisao] = useState(false)
  const [buttonMissao, setButtonMissao] = useState(false)
  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <main id="about-us">
        <div className="bg-about theme--quaternary">
          <div id='img-main' className="w-100"></div>
          <div className="container">
            <div className="row py-4">
              <div className="col-12">
                <h1 className="theme-secundary">QUEM SOMOS</h1>
                <p className="mb-2">Há 30 anos, no extremo leste de São Paulo abrimos a primeira farmácia. Hoje, temos o orgulho de ser uma das melhores redes de drogarias não só da zona leste de São Paulo, mas também com lojas na zona norte, Guarulhos, Santo André e Itaquá. São lojas modernas, com atendimento personalizado, grande variedade de produtos e entregas rápidas, como nas farmácias de bairro. E tudo sempre com o melhor preço possível. Conheça uma de nossas lojas e surpreenda-se.</p>
              </div>
              <div className="list-identify col-12 p-0 d-tp-none">
                <div className="col-12 mt-1">
                  <button className="position-relative btn theme--primary text-white w-100 theme-border-2"
                    type="button" data-toggle="collapse" data-target="#collapseVisao" aria-expanded="true"
                    aria-controls="collapseVisao" onClick={ () => setButtonVisao(true) || setButtonValores(false) || setButtonMissao(false)}>VISÃO</button>
                  <div className="row">
                    <div className="col-12">
                      <div className={buttonVisao === true ? 'collapse show' : 'collapse'} id="collapseVisao">
                        <div className="card card-body pb-0">
                          <p>Ser referência em qualidade e atendimento, liderando o mercado de drogarias da região leste de São Paulo.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-1">
                  <button className="position-relative btn theme--primary text-white w-100 theme-border-2" type="button"
                    data-toggle="collapse" data-target="#collapseMissao" aria-expanded="false"
                    aria-controls="collapseMissao" onClick={ () => setButtonMissao(true) || setButtonValores(false) || setButtonVisao(false)}>MISSÃO</button>
                  <div className="row">
                    <div className="col-12">
                      <div className={buttonMissao === true ? 'collapse show' : 'collapse'} id="collapseMissao">
                        <div className="card card-body pb-0">
                          <p>Oferecer soluções para saúde, beleza e bem-estar, contribuindo para a qualidade de vida de nossos clientes, através de um atendimento humanizado.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-1">
                  <button className="position-relative btn theme--primary text-white w-100 theme-border-2" type="button"
                    data-toggle="collapse" data-target="#collapseValores" aria-expanded="false" aria-controls="collapseValores"
                    onClick={ () => setButtonValores(true) || setButtonMissao(false) || setButtonVisao(false)}>VALORES</button>
                  <div className="row">
                    <div className="col-12">
                      <div className={buttonValores === true ? 'collapse show' : 'collapse'} id="collapseValores">
                        <div className="card card-body pb-0">
                          <p><span className="items items-bullet item-comprometimento pl-4 pr-2"></span><span className="">Comprometimento - Genuína vontade e interesse em realizar algo. Assumir um compromisso com responsabilidade. Sentir-se vinculado a algo e ter o desejo de permanecer engajado em uma ação.</span></p>
                          <p><span className="items items-bullet item-vinculo pl-4 pr-2"></span><span className="">Vínculo - Condição para estar em contato com outras pessoas e para viver relações de interdependência. Capacidade de estabelecer contato.</span></p>
                          <p><span className="items items-bullet item-cuidado pl-4 pr-2"></span><span className="">Cuidado - Preocupar-se, dar atenção e zelar por algo ou alguém, prestando apoio para o seu restabelecimento físico e/ou emocional.</span></p>
                          <p><span className="items items-bullet item-confianca pl-4 pr-2"></span><span className="">Confiança - Crença de que algo não falhará, de que é bem feito ou forte o suficiente para cumprir sua função com honestidade.</span></p>
                          <p><span className="items items-bullet item-organizacao pl-4 pr-2"></span><span className="">Organização - Maneira de agir que viabiliza o atingimento de objetivos: estabelecimento de prioridades, metodologias para ações, controle e tempo.</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="theme--quaternary">
          <div className="container">
            <div id="identify-desktop" className="row d-none d-tp-flex ">
              <div className="col-6 d-flex flex-column h-100">
                <div className="div">
                  <h2 className="theme-primary ml-5"><span className="items item-visao"></span>VISÃO</h2>
                  <p>Ser referência em qualidade e atendimento, liderando o mercado de drogarias da região leste de São Paulo.</p>
                </div>
                <div className="div">
                  <h2 className="theme-primary ml-5 "> <span className="items item-missao"></span> MISSÃO</h2>
                  <p>Oferecer soluções para saúde, beleza e bem-estar, contribuindo para a qualidade de vida de nossos clientes, através de um atendimento humanizado.</p>
                </div>
              </div>
              <div className="col-6 valores">
                <h2 className="theme-primary ml-5 "> <span className="items item-valores"></span> VALORES</h2>
                <ul>
                  <li><p><span className="items items-bullet item-comprometimento"></span><span className="ml-3 pl-3 ">Comprometimento - Genuína vontade e interesse em realizar algo. Assumir um compromisso com responsabilidade. Sentir-se vinculado a algo e ter o desejo de permanecer engajado em uma ação.</span></p></li>
                  <li><p><span className="items items-bullet item-vinculo"></span><span className="ml-3 pl-3">Vínculo - Condição para estar em contato com outras pessoas e para viver relações de interdependência. Capacidade de estabelecer contato.</span></p></li>
                  <li><p><span className="items items-bullet item-cuidado"></span><span className="ml-3 pl-3">Cuidado - Preocupar-se, dar atenção e zelar por algo ou alguém, prestando apoio para o seu restabelecimento físico e/ou emocional.</span></p></li>
                  <li><p><span className="items items-bullet item-confianca"></span><span className="ml-3 pl-3">Confiança - Crença de que algo não falhará, de que é bem feito ou forte o suficiente para cumprir sua função com honestidade.</span></p></li>
                  <li><p><span className="items items-bullet item-organizacao"></span><span className="ml-3 pl-3">Organização -Maneira de agir que viabiliza o atingimento de objetivos: estabelecimento de prioridades, metodologias para ações, controle e tempo.</span></p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  )
}
export default QuemSomos
