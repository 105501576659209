import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../../components/Banner'
import Whatsapp from '../../assets/icons/wpp.svg'

export default function Pagina (prop) {
  const { pagina } = prop
  // const [setMostrarTexto] = useState(false)

  // const handleHover = () => {
  //   setMostrarTexto(true)
  // }

  // const handleLeave = () => {
  //   setMostrarTexto(false)
  // }
  // const botaoClasses = `d-flex flex-row-reverse ${mostrarTexto ? 'botao-whats-fixo-true' : 'botao-whats-fixo'}`
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${pagina.metaTitle}`} | Drogaleste</title>
        <meta name="title" content={`${pagina.metaTitle}`} />
        <meta name="description" content={pagina.metaDescription} />
        <meta name="keywords" content={pagina.tags.join(', ')} />
        <meta name="author" content="Drogaleste" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pagina.metaTitle}`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.drogaleste.com.br${pagina.path}`} />
        <meta
          property="og:image"
          content={'https://www.drogaleste.com.br'}
        />
        <meta property="og:description" content={pagina.metaDescription} />
      </Helmet>
      {pagina.possuiBanner
        ? <Banner
          desktop={pagina.desktopBanner.localFile.childImageSharp.gatsbyImageData}
          mobile={pagina.desktopBanner.localFile.childImageSharp.gatsbyImageData}
          alt="Banner"
        />
        : ''}
        {/* <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762'>
    <button
    className={botaoClasses}
    onMouseEnter={handleHover}
    onMouseLeave={handleLeave}
  >
      {mostrarTexto && (
        <p className='mt-3'>
          Solicite agora!
        </p>
      )}
      <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' />
  </button>
  </a> */}
      <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762'>
        <div className=''>
          <div className='drogaleste-container position-fixed' role='button'>
            <div className='drogaleste-theme d-flex'>
            </div>
            <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' crossOrigin='anonymous'/>
          </div>
        </div>
      </a>
    </Fragment>
  )
}
